@font-face {
    font-family: 'SFProDisplay Regular';
    src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Regular.woff') format('woff'),
        url('../fonts/SFProDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay SemiBold';
    src: url('../fonts/SFProDisplay-Semibold.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Semibold.woff') format('woff'),
        url('../fonts/SFProDisplay-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFUIDisplay SemiBold';
    src: url('../fonts/sf-ui-display-semibold.woff2') format('woff2'),
        url('../fonts/sf-ui-display-semibold.woff') format('woff'),
        url('../fonts/sf-ui-display-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFUIDisplay Bold';
    src: url('../fonts/sf-ui-display-bold.woff2') format('woff2'),
        url('../fonts/sf-ui-display-bold.woff') format('woff'),
        url('../fonts/sf-ui-display-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SfUiText Regular';
    src: url('../fonts/SF-UI-Text-Regular.woff') format('woff'),
        url('../fonts/SF-UI-Text-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins SemiBold';
    src: url('../fonts/Poppins-SemiBold.woff') format('woff'), url('../fonts/Poppins-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

